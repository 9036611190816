// Dependency
import React from "react";
import { Link, graphql } from 'gatsby';

// Internationalization
import { useTranslation } from 'react-i18next';

// Services
import UtilsService from "../../services/utils.service";

// Components
import Layout from "../../components/layouts";
import SectionTitle from "../../components/section-title/section-title";
import BlogPostGrid from "../../components/blog-post/blog-post-grid/blog-post-grid";
import BlogPostList from "../../components/blog-post/blog-post-list/blog-post-list";
import Tags from "../../components/tags/tags";
import Subscribe from "../../components/subscribe/subscribe";
//import Hero from "../../components/hero/hero";
import InstaFeed from "../../components/instafeed/instafeed";
import FooterTrust from "../../components/trustpilot/footer-trustpilot";

// Assets
//import thumbnail from '../../components/blog-post/assets/post/thumbnail.jpg';
//import avatar from '../../components/blog-post/blog-post-thumbnail/assets/author.jpg';
import HeaderCats from "../../components/header/cats";

/*const twoColumnsData = [
  {
      title: 'Prestationsångest och träningsglädje med Maya Parnevik',
      imageUrl: thumbnail,
      desc: 'Linnea Stensils är 25-åringen från Vaxholm som paddlat sig och sin kanot hela vägen till sommar-OS i Tokyo.',
      author: {
          categories: 'Carl Norström i Träning',
          readingtime: '6',
          date: '21 maj 2021',
          avatar: avatar
      }
  },
  {
      title: 'Vetenskapen bakom Flowgun',
      imageUrl: thumbnail,
      desc: 'Linnea Stensils är 25-åringen från Vaxholm som paddlat sig och sin kanot hela vägen till sommar-OS i Tokyo.',
      author: {
          categories: 'Carl Norström i Träning',
          readingtime: '6',
          date: '21 maj 2021',
          avatar: avatar
      }
  },
  {
      title: 'Flowlife förbereder: Linnea Stensils',
      imageUrl: thumbnail,
      desc: 'Linnea Stensils är 25-åringen från Vaxholm som paddlat sig och sin kanot hela vägen till sommar-OS i Tokyo.',
      author: {
          categories: 'Carl Norström i Träning',
          readingtime: '6',
          date: '21 maj 2021',
          avatar: avatar
      }
  },
  {
      title: 'Flowlife förbereder: Agnes Alexiusson',
      imageUrl: thumbnail,
      desc: 'Linnea Stensils är 25-åringen från Vaxholm som paddlat sig och sin kanot hela vägen till sommar-OS i Tokyo.',
      author: {
          categories: 'Carl Norström i Träning',
          readingtime: '6',
          date: '21 maj 2021',
          avatar: avatar
      }
  },
];*/

/*const postListData = [
  {
      authorUrl: avatar,
      categories: ['Carl Norström', 'Träning'],
      title: 'Prestationsångest och träningsglädje med Maya Parnevik',
      date: '21 maj 2021',
      readingTime: '6 minuter läsning'
  },
  {
      authorUrl: avatar,
      categories: ['Carl Norström', 'Återhämtning'],
      title: 'Löparskador – hur gör jag för att undvika dem?',
      date: '21 maj 2021',
      readingTime: '6 minuter läsning'
  },
  {
      authorUrl: avatar,
      categories: ['Carl Norström', 'Återhämtning'],
      title: 'Alla uttagna svenskar och våra medaljhopp',
      date: '21 maj 2021',
      readingTime: '6 minuter läsning'
  },
  {
      authorUrl: avatar,
      categories: ['Carl Norström', 'Träning'],
      title: 'Officiell leverantör till Sveriges Olympiska Kommitté',
      date: '21 maj 2021',
      readingTime: '6 minuter läsning'
  },
  {
      authorUrl: avatar,
      categories: ['Carl Norström', 'Återhämtning'],
      title: 'Erik Skoglund om vad återhämtning betytt för honom',
      date: '21 maj 2021',
      readingTime: '6 minuter läsning'
  },
  {
      authorUrl: avatar,
      categories: ['Carl Norström', 'Återhämtning'],
      title: 'Massagepistol och Padel',
      date: '21 maj 2021',
      readingTime: '6 minuter läsning'
  },
];*/

/*const headerCats = [
  {
      name: 'Featured',
      link: '/shop',
  },
  {
      name: 'Träning',
      link: '/shop',
  },
  {
      name: 'Återhämtning',
      link: '/shop',
  },
  {
      name: 'Stretching',
      link: '/shop',
  },
  {
      name: 'Flow',
      link: '/shop',
  },
];*/

// Template
const BlogCategoryPage = (props) => {

  const pageData = props.data.datoCmsBlogPage;
  // Extract latest blog articles
  const latestBlogPostItems = props.data.latestPosts.nodes;
  // Extract top story blog articles
  const topStoryBlogPostItems = props.data.topStory.nodes;
  //  Extract instagram feed data
  //const instagramFeedItems = props.data.allInstaNode.nodes;
  // All categories
  //const categories = props.data.allCategories.nodes;
  // Translation Function
  const { t } = useTranslation();
  // Get locale since we are using relative URLs
  const locale = UtilsService.getLocaleFromUrl();

  /*const mediaData = {
      mobileImg: '',
      desktopImg: '',
      videoSrcURL:  '',
      videoSrcURLMobile: '',

  }*/
    return (
      <Layout country={props.pageContext.country} location={props.location}>
        <HeaderCats data={pageData.categories} />
        {/*<div className="cd-blog-hide-mobile">
          <Hero
            cname={"cd-hero-small-text"}
            mediaData={mediaData}
          >
            <h1><span>Flowlife&nbsp;</span><span>Blog</span></h1>
          </Hero>
          </div>*/}
          <div className="cd-max-width " id="category">
              <div className="layout--sidebar cd-page-section">
                <div className="content">
                  <section>
                    <SectionTitle title={props.pageContext.name} color={"pink"}>
                      <Link to={`/${locale.country}/${t('slug.blog')}/`}><i className="material-icons">navigate_before</i></Link>
                    </SectionTitle>
                    <BlogPostGrid
                      columns={"three"}
                      data={latestBlogPostItems}
                    />
                  </section>
                </div>
                <div className="sidebar cd-blog-hide-mobile">
                  <section>
                    <SectionTitle title={"Top Stories"} color={"pink"} />
                    <BlogPostList data={topStoryBlogPostItems} />
                    <div>
                      <h4>{t('widgets.topics')}</h4>
                      <Tags categories={pageData.categories} />
                    </div>
                    <div>
                      <h4>{t('widgets.subscribe.title')}</h4>
                      <div>
                        <Subscribe
                          size={"small"}
                          description={`${t('widgets.subscribe.description')}`}
                          label={`${t('widgets.subscribe.label')}`}
                        />
                      </div>
                    </div>
                  </section>
                </div>
            </div>
          </div>
      </Layout>
  );
};

export default BlogCategoryPage;

// GraphQL to get Dato data
export const query = graphql`
  query ($slug: [String], $locale: String!) {
    latestPosts: allDatoCmsArticle(
      filter: {slug: {ne: null}, locale: {eq: $locale}, categories: {elemMatch: {slug: {in: $slug}}}}
      sort: {fields: date, order: DESC}
    ) {
      nodes {
        title
        slug
        seoSlug
        readingTime
        id
        featuredMedia {
             fluid{
              ...GatsbyDatoCmsFluid
            }
          title
        }
        categories {
          name
          slug
          id
        }
        author {
          id
          name
          featuredImage {
             fluid{
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    topStory: allDatoCmsArticle(
      filter: {slug: {ne: null}, locale: {eq: $locale}, topStory: {eq: true}, categories: {elemMatch: {slug: {in: $slug}}}}
      limit: 8
      sort: {fields: date, order: DESC}
    ) {
      nodes {
        title
        topStory
        slug
        seoSlug
        date
        readingTime
        id
        featuredMedia {
          fluid{
            ...GatsbyDatoCmsFluid
          }
          title
        }
        categories {
          name
          slug
          id
        }
       author {
          id
          name
          featuredImage {
             fluid{
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
   datoCmsBlogPage (locale: {eq: $locale}){
        locale
        title
        seoSlug
        desktopHero{
          fluid{
              ...GatsbyDatoCmsFluid
            }
        }
        mobileHero{
          fluid{
              ...GatsbyDatoCmsFluid
            }
        }
        categories{
          id
          name
          slug
          locale
        }
        seoMetaTags {
          tags
          id
        }
     }
    allCategories: allDatoCmsCategory(
      filter: {locale: {eq: $locale}}
    ) {
      nodes {
        id
        name
        slug
        locale
      }
    }
  }
`;